import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { FaX } from "react-icons/fa6";
export default function JotForm({
	open,
	setOpen,
	src,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
	src: string;
}) {
	if (!open) return null;
	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			inset={0}
			position="fixed"
			zIndex={1000}
			backdropFilter="blur(5px)"
			onClick={() => setOpen(false)}
		>
			<Flex
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				w="100%"
				h="100%"
				maxW="1000px"
				maxH="589px"
				overflow={"hidden"}
				position="relative"
			>
				<Box
					position="absolute"
					top={0}
					right={0}
					bg="black"
					color="white"
					p={4}
					borderRadius={"0%"}
					onClick={e => {
						e.stopPropagation();

						setOpen(false);
					}}
					role="button"
					zIndex={1000}
				>
					<FaX size={16} />
				</Box>
				<iframe
					onClick={e => e.stopPropagation()}
					id="JotFormIFrame-250074781081353"
					title="Information Request Form - Talent"
					onLoad={() => {
						window.parent.scrollTo(0, 0);
					}}
					allowTransparency={true}
					allow="geolocation; microphone; camera; fullscreen"
					src={src}
					frameBorder="0"
					style={{
						minWidth: "100%",
						maxWidth: "100%",
						height: "539px",
						border: "none",
					}}
					scrolling="yes"
				></iframe>
				<script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>
				<script>
					window.jotformEmbedHandler("iframe[id='JotFormIFrame-250074781081353']",
					"https://form.jotform.com/")
				</script>
			</Flex>
		</Flex>
	);
}
