import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import type { WrapPageElementNodeArgs } from "gatsby";
import { MainProvider } from "./MainContext";
import { Helmet } from "react-helmet";
//@ts-ignore
import theme from "./styles/theme";

export const WrapRootElement = ({
	element,
}: Pick<WrapPageElementNodeArgs, "element">) => (
	<MainProvider>
		<Helmet>
			<script type="text/javascript">
				{`
					(function (w,d) {
						var loader = function () {
							var s = d.createElement("script"),
									tag = d.getElementsByTagName("script")[0];
							s.src="https://cdn.iubenda.com/iubenda.js";
							tag.parentNode.insertBefore(s,tag);
						};
						if(w.addEventListener){
							w.addEventListener("load", loader, false);
						} else if(w.attachEvent){
							w.attachEvent("onload", loader);
						} else {
							w.onload = loader;
						}
					})(window, document);
				`}
			</script>
			<script type="text/javascript">
				{`
					var _iub = _iub || [];
					_iub.csConfiguration = {
						"siteId": 3870639,
						"cookiePolicyId": 67452015,
						"lang": "it",
						"storage": {
							"useSiteId": true
						},
						"banner": {
							"position": "bottom",
							"theme": "dark",
							"backgroundColor": "#000000",
							"textColor": "#ffffff",
							"acceptButtonDisplay": true,
							"acceptButtonColor": "#fff970",
							"acceptButtonTextColor": "#ff0000",
							"otherButtonDisplay": true,
							"otherButtonColor": "#798c93",
							"otherButtonTextColor": "#ffffff",
						}
					};
				`}
			</script>
			<script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3870639.js"></script>
			<script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
		</Helmet>
		<ChakraProvider theme={theme}>{element}</ChakraProvider>
	</MainProvider>
);
